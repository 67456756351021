import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { Supplier, ISupplier } from "./Supplier";
import { Contact, IContact } from "./Contact";
import { Site, ISite } from "./Site";
import { IRegionSupplierSite, RegionSupplierSite } from "./RegionSupplierSite";

export interface IRegion {
    id: number;
    buyerID: number;
    description: string;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;

    contactCount: number;
    supplierCount: number;
    siteCount: number;

    contacts: Array<IContact>;
    suppliers: Array<ISupplier>;
    sites: Array<ISite>;
    supplierSites: Array<IRegionSupplierSite>;
}

export class Region implements IRegion {

    constructor(data?: IRegion) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IRegion) {
        mapData(data, { 
            root: () => this,
            suppliers: () => new Supplier(),
            contacts: () => new Contact(),
            sites: () => new Site(),
            supplierSites: () => new RegionSupplierSite()
        });
    }

    id: number = 0;
    buyerID: number = 0;
    description: string = "";
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;

    contactCount: number = 0;
    supplierCount: number = 0;
    siteCount: number = 0;

    contacts: Array<Contact> = [];
    suppliers: Array<Supplier> = [];
    sites: Array<Site> = [];
    supplierSites: Array<RegionSupplierSite> = [];

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get isDeleted() {
        return utils.hasDateValue(this.deleted);
    }
}