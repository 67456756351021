import { mapData } from "@/stuff/DataMapping";

export interface IRegionSupplierSite {

    regionID: number;
    supplierID: number;
    siteID: number;

}

export class RegionSupplierSite  implements IRegionSupplierSite {

    constructor(data?: IRegionSupplierSite) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IRegionSupplierSite) {
        mapData(data, { root: () => this });
    }

    regionID: number = 0;
    supplierID: number = 0;
    siteID: number = 0;

}