
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import { required, email } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import { Watch } from "vue-property-decorator";
    import * as toastr from "toastr";

    import store from "@/store/store";
    import router from "@/router/router";
    import apiClient from "@/stuff/ApiClient";
    import { ILookupItem, LookupItem } from "@/model/LookupItem";
    import { Region as ModelRegion } from "@/model/Region";
    import { ISupplier } from "@/model/Supplier";
    import { IContact, Contact } from "@/model/Contact";
    import { ISite, Site } from "@/model/Site";
    import { Buyer } from "@/model/Buyer";
    import { Supplier } from "@/model/Supplier";
    import {ContactSearchParameters } from "@/model/ContactSearchParameters";
    import {SiteSearchParameters } from "@/model/SiteSearchParameters";
    import utils from "@/stuff/Utils";
    import { TriState } from "@/model/Enums";

    @Component({
        components: { ApiButton }
    })
    export default class Region extends Vue {

        async mounted() {
            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;
            const regionID = +this.$router.currentRoute.params.regionID;
            await Promise.all([
                store.dispatch("loadCompanyTypeList"),
                store.dispatch("loadCountryList"),
                store.dispatch("loadBuyerList"),
                this.load(regionID),
            ]);
        }

        //
        // -- properties
        //

        isBuyerZone: boolean = false;

        tabIndex: number = 0;

        readonly region = new ModelRegion();
        readonly buyer = new Buyer();
        readonly contact = new Contact();
        readonly site = new Site();

        private siteID: number = 0;

        // computed
        get title(): string {
            if (!this.region) return "- - -";
            if (utils.isEmptyId(this.region.id)) return "New Region";
            return this.region.description ? this.region.description : "(No region name)";
        }

        get contactDialogueTitle(): string {
            if (!this.contact) return "- - -";
            if (utils.isEmptyId(this.contact.id)) return "New Contact";
            return "Edit " + this.contact.fullname;
        }

        get siteDialogueTitle(): string {
            if (!this.site) return "- - -";
            if (utils.isEmptyId(this.site.id)) return "New Location";
            return "Edit " + this.site.description;
        }

        get companyTypeList(): Array<ILookupItem> { return this.$store.state.companyTypeList; }
        get companyTypeOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.companyTypeList); }

        get countryList(): Array<ILookupItem> { return this.$store.state.countryList; }
        get countryOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.countryList); }

        get buyerList(): Array<ILookupItem> { return this.$store.state.buyerList; }
        get buyerOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.buyerList); }

        private get siteOptions(): Array<ILookupItem> {
            return [ 
                new LookupItem( { id: 0, description: "Any Location", isArchived: false } ), 
                ...this.region.sites.map(site => new LookupItem( { id: site.id, description: site.description, isArchived: site.isDeleted } )) 
            ];
        }

        get canDeleteRegion() {
            return !this.isBuyerZone && this.tabIndex === 0 && !this.region.isNew && !utils.hasDateValue(this.region.deleted);
        }

        get saveRegionButtonText() {
            return utils.hasDateValue(this.region.deleted) ? "Save Region and Un-archive" : "Save Region";
        }

        get canDeleteContact() {
            return !this.isBuyerZone && this.tabIndex === 1 && !this.contact.isNew && !utils.hasDateValue(this.contact.deleted);
        }

        get saveContactButtonText() {
            return utils.hasDateValue(this.contact.deleted) ? "Save Contact and Un-archive" : "Save Contact";
        }

        get canDeleteSite() {
            return ((!this.isBuyerZone && this.tabIndex === 1) || (this.isBuyerZone && this.tabIndex === 0)) && 
                    !this.site.isNew && !utils.hasDateValue(this.site.deleted);
        }

        get saveSiteButtonText() {
            return utils.hasDateValue(this.site.deleted) ? "Save Location and Un-archive" : "Save Location";
        }

        get filteredSuppliers(): Array<Supplier> {
            return this.region.suppliers.filter(supplier => {
                return utils.isEmptyId(this.siteID) || 
                    this.region.supplierSites.filter(ss => ss.supplierID == supplier.id && ss.siteID == this.siteID).length > 0
            });
        }

        @Watch("region.buyerID", { immediate: true, deep: true })
        onBuyerIdChanged() {
            this.loadBuyer();
        }

        //
        // -- methods
        //

        goToRegions() {
            // show region search (we could go back - but no guarantee that we came from search)
            // TODO - maybe check history and go back?

            if (this.isBuyerZone) {
                router.push("/buyerZone/regions");
            }
            else {
                router.push("/regions");
            }
        }

        goToSupplier(supplier: ISupplier) {
            router.push("/supplier/" + supplier.id);
        }

        async load(regionID: number) {
            this.$v.$reset();
            utils.resetObject(this.region);
            if(regionID > 0) {
                const serverRegionData = await apiClient.get(`api/region/Load?id=${regionID}`);
                // nb - update maps suppliers, sites and contacts too
                this.region.update(serverRegionData);
            }
        }

        async saveRegion(event: Event) {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.region.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            const response: { newID: number } =  await apiClient.post("/api/region/save", this.region, event);
     
            if (this.region.isNew) {
                this.region.id = response.newID;
                // hmm...
                history.replaceState({}, "", this.$route.path.replace("/0", "/" + response.newID));
            }
            else if(this.region.isDeleted) {
                // saving un-deletes
                this.region.deleted = new Date(utils.emptyDateValue);
                this.region.deletedByUserID = 0;
            }

            toastr.success("Saved");           
        }

        async deleteRegion(event: Event) {
            if (this.region.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.region.description + "'?", {
                title: "Delete Region",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/region/delete", this.region, event);
            toastr.warning("Deleted");

            this.goToRegions();
        }

        async loadBuyer() {
            const id = this.region.buyerID;
            if(!id) {
                utils.resetObject(this.buyer);
                return;
            }
            const buyerData = await apiClient.get(`api/buyer/Load?id=${id}`);
            this.buyer.update(buyerData);
        }

        //
        // -- contacts
        //

        async refreshContacts() {
            utils.resetObject(this.contact);
            if(!this.region.id) return;
            const parameters = new ContactSearchParameters();
            parameters.regionID = this.region.id;
            parameters.isDeleted = TriState.False;
            const responseData = await apiClient.post("api/contact/search", parameters);
            this.region.contacts = responseData.list.map((c: IContact) => new Contact(c));
            this.region.contactCount = this.region.contacts.length;
        }

        async editContact(contactData: IContact) {
            this.contact.update(contactData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("contactDialogue");

            //TODO - show waiting animation

            // strictly speaking we don't *have* to go to the server here - but data will be less stale if we do
            const serverContactData = await apiClient.get(`api/contact/Load?id=${contactData.id}`);

            console.log("Back in edit with server data...");
            console.log(serverContactData);

            this.contact.update(serverContactData);
        }

        editNewContact() {
            utils.resetObject(this.contact);
            this.$v.$reset();
            this.contact.regionID = this.region.id;
            this.$bvModal.show("contactDialogue");
        }

        cancelContact() {
            this.$bvModal.hide("contactDialogue");
            utils.resetObject(this.contact);
        }

        async saveContact(event: Event) {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.contact.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            const response: { newID: number } =  await apiClient.post("/api/contact/save", this.contact, event);
            if (this.contact.isNew) {
                this.contact.id = response.newID;
            }
            toastr.success("Saved");           
            this.$bvModal.hide("contactDialogue");
            await this.refreshContacts();
        }

        async deleteContact(event: Event) {
            if (this.contact.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.contact.fullname + "'?", {
                title: "Delete Contact",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/contact/delete", this.contact, event);
            toastr.warning("Deleted");
            await this.refreshContacts();
        }

        //
        // -- sites
        //

        async refreshSites() {
            utils.resetObject(this.site);
            if(!this.region.id) return;
            const parameters = new SiteSearchParameters();
            parameters.regionID = this.region.id;
            parameters.isDeleted = TriState.False;
            const sitesData = await apiClient.post("api/site/search", parameters);
            this.region.sites = sitesData.map((c: ISite) => new Site(c));
            this.region.siteCount = this.region.sites.length;
        }

        async editSite(siteData: ISite) {
            this.site.update(siteData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("siteDialogue");

            // strictly speaking we don't *have* to go to the server here - but data will be less stale if we do
            const serverSiteData = await apiClient.get(`api/site/Load?id=${siteData.id}`);

            console.log("Back in edit with server data...");
            console.log(serverSiteData);

            this.site.update(serverSiteData);
        }

        editNewSite() {
            utils.resetObject(this.site);
            this.$v.$reset();
            this.site.regionID = this.region.id;
            this.$bvModal.show("siteDialogue");
        }

        cancelSite() {
            this.$bvModal.hide("siteDialogue");
            utils.resetObject(this.site);
        }

        async saveSite(event: Event) {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.site.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            const response: { newID: number } =  await apiClient.post("/api/site/save", this.site, event);
            if (this.site.isNew) {
                this.site.id = response.newID;
            }
            toastr.success("Saved");           
            this.$bvModal.hide("siteDialogue");
            await this.refreshSites();
        }

        async deleteSite(event: Event) {
            if (this.site.isNew) return;
            const ce = this.$createElement;
            const messageVNodes = [ ce('div', {}, [
                ce('div', {}, [
                    ce('div', {}, [`Do you want to delete '${this.site.description}'?`]),
                    ce('br', {}, []),
                    ce('div', {}, ["It will delete any selected sites for supplier-region relationships too."])
                ])
            ])];
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm(messageVNodes, {
                title: "Delete Location",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/site/delete", this.site, event);
            toastr.warning("Deleted");
            await this.refreshSites();
            this.$bvModal.hide("siteDialogue");
        }

        viewSiteSuppliers(site: Site) {
            this.siteID = site.id;
            this.tabIndex = 3;
        }

        //
        // -- validation
        //
        
        @Validations()
        validations() {
            const validations = {
                region: {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                contact: {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                site: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.region.buyerID = { isNonZero: (value: number) => +value > 0 };
            validations.region.description = { required };

            validations.contact.forename = { required };
            validations.contact.surname = { required };
            validations.contact.email = { email };

            validations.site.description = { required };

            return validations;
        }
    }
